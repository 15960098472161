import React from 'react'
import Css from './Detail.module.css'

function Detail() {
    return (
        <>
            <div className={Css.detailpage}>
                <div className={Css.detailcourse}>
                
                </div>
            </div>
        </>
    )
}

export default Detail